import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import {
  Button,
  ButtonPriority as ButtonPRIORITY,
  ButtonSize as ButtonSIZE,
} from 'wix-ui-tpa';
import { ActionBlockerAppearance } from '../../../../../components-shared/ActionBlocker/ActionBlocker';
import { PreviewActionBlockerContainer } from '../../../../../components-shared/ActionBlocker/PreviewActionBlockerContainer';
import { dataHooks } from '@wix/data-hooks';

import { st, classes } from './LeaveTheChallenge.st.css';

export interface ILeaveTheChallengeProps {
  onLeave(): void;
  onCancel(): void;
}

export const leaveTheChallengeDataHooks = dataHooks<{
  main;
  completeBtn;
}>('leave-the-challenge');

export const LeaveTheChallenge: React.FC<ILeaveTheChallengeProps> = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  return (
    <div
      className={st(classes.root, {
        mobile: isMobile,
      })}
      data-hook={leaveTheChallengeDataHooks.main()}
    >
      <h1 className={classes.title} data-hook="a-123">
        {t('leave-the-challenge.title')}
      </h1>
      <p className={classes.description}>
        {t('leave-the-challenge.description')}
      </p>

      <div className={classes.controls}>
        <Button
          disabled={false}
          className={classes.cancel}
          priority={ButtonPRIORITY.basicSecondary}
          size={ButtonSIZE.medium}
          onClick={props.onCancel}
        >
          {t('leave-the-challenge.cancel')}
        </Button>
        <PreviewActionBlockerContainer
          appearance={ActionBlockerAppearance.PopoverAppearance}
        >
          <Button
            disabled={false}
            className={classes.cta}
            data-hook={leaveTheChallengeDataHooks.completeBtn()}
            priority={ButtonPRIORITY.primary}
            size={ButtonSIZE.medium}
            onClick={props.onLeave}
          >
            {t('leave-the-challenge.cta')}
          </Button>
        </PreviewActionBlockerContainer>
      </div>
    </div>
  );
};

LeaveTheChallenge.displayName = 'LeaveTheChallenge';
